"use client";

import {
  type ReviewPageType,
  type ConfigEnv,
  type GenericLandingPage,
  type GenericReview,
} from "./config/eval";
import { evalFragment } from "./config/eval.client";
import { useHighlightStore, usePreviewPageStore } from "./preview";
import { useEffect, useRef } from "react";
import { type LandingPageChoice } from "./landing";
import type { Config } from "@lib/config/utils";

export type ConfigPreview = {
  config: Config;
  viewPath: string[];
  env: ConfigEnv;
};

export type ConfigPreviewMessage = {
  preview_config: GenericLandingPage | GenericReview;
  view_path: string[];
  env: ConfigEnv;
  type: string;
};

type Listeners = {
  onUpdate?: (data: ConfigPreview) => void;
  onBlockHover: (id: number) => void;
  onBlockUnhover: (id: number) => void;
  onPageChange: (data: number | "Next" | "Prev") => void;
  zoom: (value: number) => void;
};

type IFrameMessage =
  | {
      tag: "UpdateConfig";
      data: string;
    }
  | {
      tag: "HoverOverBlock" | "HoverOffBlock";
      message: number;
    }
  | {
      tag: "Zoom";
      data: number;
    }
  | {
      tag: "ChangePage" | "ChangeLandingPage";
      data: "Next" | "Prev";
    }
  | {
      tag: "SelectPage" | "SelectLandingPage";
      data: string;
    };

export function initPreviewListeners({
  onUpdate,
  onBlockHover,
  onBlockUnhover,
  onPageChange,
  zoom,
}: Listeners) {
  window.addEventListener(
    "message",
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    async ({ data }: { data: IFrameMessage }) => {
      switch (data.tag) {
        case "UpdateConfig": {
          const config = await evalFragment(
            JSON.parse(data.data) as ConfigPreviewMessage,
          );
          onUpdate?.(config);
          break;
        }
        case "HoverOverBlock":
          onBlockHover(data.message);
          break;
        case "HoverOffBlock":
          onBlockUnhover(data.message);
          break;
        case "ChangePage":
        case "ChangeLandingPage":
          onPageChange(data.data);
          break;
        case "SelectPage":
        case "SelectLandingPage":
          onPageChange(parseInt(data.data));
          break;
        case "Zoom":
          zoom(data.data);
          break;
        default:
          break;
      }
    },
  );
}

export const usePreviewListeners = (args: {
  pageList?: { label: string; value: LandingPageChoice | ReviewPageType }[];
  type?: string;
  onUpdate?: (data: ConfigPreview) => void;
}) => {
  const setHighlightedIndex = useHighlightStore(
    (state) => state.setHighlightedIndex,
  );

  // Using the ref is a workaround to be able to use state with event listeners
  // without the ref the state is always the initial state
  const page = usePreviewPageStore((state) => state.page);
  const _setPage = usePreviewPageStore((state) => state.setPage);
  const pageRef = useRef(page);
  const setPage = (page: LandingPageChoice | ReviewPageType | number) => {
    if (pageRef.current !== page) {
      pageRef.current = page;
      _setPage(page);
    }
  };

  return useEffect(() => {
    // On load actions
    if (window.parent && args.pageList) {
      sendPageList(args.pageList);
      sendPageChangeMessage(args.pageList[0].value);
    }

    // Listeners
    initPreviewListeners({
      ...args,
      onBlockHover: (index) => setHighlightedIndex(index),
      onBlockUnhover: () => setHighlightedIndex(null),
      onPageChange: (data) => {
        const { pageList } = args;
        if (!pageList) return;

        if (args.type === "review") {
          if (typeof data === "number") {
            setPage(data);
            sendPageChangeMessage(pageList[data].value);
            return;
          }
          if (data === "Next") {
            const newPageIndex = Number(pageRef.current) + 1;
            if (newPageIndex < pageList.length) {
              setPage(newPageIndex);
              sendPageChangeMessage(pageList[newPageIndex].value);
            }
            return;
          } else if (data === "Prev") {
            const newPageIndex = Number(pageRef.current) - 1;
            if (newPageIndex >= 0) {
              setPage(newPageIndex);
              sendPageChangeMessage(pageList[newPageIndex].value);
            }
            return;
          }
        } else {
          if (typeof data === "number") {
            setPage(pageList[data].value);
            sendPageChangeMessage(pageList[data].value);
            return;
          }

          const pageIndex = pageList.findIndex(
            (p) => p.value === pageRef.current,
          );

          const nextIndex = data === "Next" ? pageIndex + 1 : pageIndex - 1;
          if (nextIndex < 0 || nextIndex > pageList.length - 1) return;

          setPage(pageList[nextIndex].value);
          sendPageChangeMessage(pageList[nextIndex].value);
        }
      },
      zoom: (value) =>
        (document.documentElement.style.fontSize = `${value / 100}rem`),
    });
  }, []);
};

function sendPageList(data: { label: string; value: string }[]) {
  window.parent?.postMessage(
    {
      tag: "PageList",
      data,
    },
    "*",
  );
}

function sendPageChangeMessage(data: string) {
  window.parent?.postMessage(
    {
      tag: "CurrentPage",
      data,
    },
    "*",
  );
}
