import { ErrorPreview } from "@/components/pages/error-preview";
import { Preview } from "@/components/pages/preview";
import { ShareablePreview } from "@/components/pages/shareable-preview";
import type { Activity, ActivityFlowConsents } from "@lib/activity";
import type { Config } from "@lib/config/utils";
import { type LandingPageChoice } from "@lib/landing";
import { createRoute } from "@tanstack/react-router";
import { Route as rootRoute, getContext } from "./root";
import { z } from "zod";
import { fallback, zodValidator } from "@tanstack/zod-adapter";
import type { ConfigEnv } from "@lib/config/eval";

export type Props = {
  activity: Activity;
  config: Config;
  encodedActivityId: string;
  flowConsents: ActivityFlowConsents;
};

const Schema = z
  .enum(["out_of_stock", "closed", "claimed", "", "landing", "complete"])
  .or(z.coerce.number())
  .transform((v) =>
    v === "" ? "landing" : v === "complete" ? "completion" : v,
  );

export const ConfigRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/flow/config-preview/$flowType/$encondedActivityId/$id/$",
  validateSearch: zodValidator(
    z.object({
      env: fallback(z.string(), ""),
    }),
  ),
  loader: ({ params }) => ({
    ...params,
    ...getContext<Props>(),
  }),
  component: function Page() {
    const props = ConfigRoute.useLoaderData();
    const { env } = ConfigRoute.useSearch();

    const parsedEnv = Object.fromEntries(
      new URLSearchParams(decodeURIComponent(env)).entries(),
    ) as unknown as ConfigEnv;

    if (props._splat?.startsWith("error")) {
      const errorType = props._splat.split("/")[1] ?? "out_of_stock";
      return (
        <ErrorPreview
          type={props.flowType}
          activity={props.activity}
          page={errorType as LandingPageChoice}
          config={props.config}
          env={parsedEnv}
        />
      );
    }
    const page = Schema.safeParse(props._splat);
    if (page.success) {
      return (
        <Preview
          type={props.flowType}
          activity={props.activity}
          page={page.data}
          flowConsents={props.flowConsents}
          config={props.config}
          env={parsedEnv}
        />
      );
    }
  },
});

export const ShareableRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/flow/shareable-preview/$flowType/$encondedActivityId/$id/$",
  loader: ({ params }) => ({ ...params, ...getContext<Props>() }),
  component: function Page() {
    const { activity, config, encodedActivityId, flowType, flowConsents } =
      ShareableRoute.useLoaderData();

    return (
      <ShareablePreview
        activity={activity}
        config={config}
        encodedActivityId={encodedActivityId}
        type={flowType}
        flowConsents={flowConsents}
      />
    );
  },
});
