import { useTranslations } from "@/components/context/translations";

export function ServerError() {
  const t = useTranslations({ skipValidation: true });

  return (
    <div className="text-center shadow-lg shadow-gray-300 border border-gray-100 rounded-lg w-[363px] mx-auto my-20">
      <div className="flex flex-col items-center justify-between p-6">
        <h1 className="text-2xl font-semibold pb-4 leading-[33.6px]">
          {t("Internal Server Error")}
        </h1>
        <p className="pb-4 text-base font-normal">
          {t(
            "Something unexpected occured and we were unable to process your request.",
          )}
        </p>
      </div>
    </div>
  );
}
