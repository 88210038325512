// Taken from https://ui.shadcn.com/docs/components/input
import * as React from "react";

import { cn } from "@lib/utils";

export type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, style, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(
          "h-12 w-full border border-input bg-background px-4 py-2 font-[family-name:--field-font-name] text-base font-[--field-font-weight] shadow-md ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-[var(--placeholder-color)] read-only:opacity-70 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
          className,
        )}
        ref={ref}
        style={{
          color: "var(--input-color)",
          backgroundColor: "var(--input-background)",
          borderTopLeftRadius:
            "var(--field-border-top-left-radius, var(--input-radius))",
          borderTopRightRadius:
            "var(--field-border-top-right-radius, var(--input-radius))",
          borderBottomRightRadius:
            "var(--field-border-bottom-right-radius, var(--input-radius))",
          borderBottomLeftRadius:
            "var(--field-border-bottom-left-radius, var(--input-radius))",
          border: "var(--field-border-size) solid var(--input-border-color)",
          ...style,
        }}
        {...props}
      />
    );
  },
);
Input.displayName = "Input";

export { Input };
