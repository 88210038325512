import { clsx, type ClassValue } from "clsx";
import { type CSSProperties } from "react";
import type { FieldValues } from "react-hook-form";
import { twMerge } from "tailwind-merge";
import type { GenericLandingPage, GenericReview } from "./config/eval";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export type HSLA = {
  h: number;
  s: number;
  l: number;
  a: number;
};

export function hsla({ h, s, l, a }: HSLA) {
  return `hsla(${h}, ${s}%, ${l}%, ${a})`;
}

export function blockWidth(
  width: string | { custom: "small" | "medium" | "large" | "full" },
) {
  if (width === "global_block_width") {
    return "min(100vw, var(--block-width))";
  }

  const custom = typeof width === "string" ? width : width.custom;

  switch (custom) {
    case "small":
      return "25rem";
    case "medium":
      return "35rem";
    case "large":
      return "45rem";
    case "full":
      return "100vw";
    default:
      throw new Error(`Unexpected block width: ${custom}`);
  }
}

export function textBlockWidth(width: "small" | "medium" | "large" | "full") {
  switch (width) {
    case "small":
      return "50%";
    case "medium":
      return "75%";
    case "large":
      return "90%";
    case "full":
      return "100%";
  }
}

export function justify({
  alignment,
}: {
  alignment: "left" | "center" | "centre" | "right";
}) {
  switch (alignment) {
    case "left":
      return "start";
    case "center":
    case "centre":
      return "center";
    case "right":
      return "end";
  }
}

export function alignStyle({
  alignment,
}: {
  alignment: "left" | "center" | "centre" | "right";
}): CSSProperties {
  switch (alignment) {
    case "left":
      return {};
    case "center":
    case "centre":
      return { marginInline: "auto" };
    case "right":
      return { marginInlineStart: "auto" };
  }
}

export function justifyStyle({
  alignment,
}: {
  alignment: "left" | "center" | "centre" | "right";
}): CSSProperties {
  switch (alignment) {
    case "left":
      return {};
    case "center":
    case "centre":
      return { justifyItems: "center" };
    case "right":
      return { justifyItems: "end" };
  }
}

export function justifyColumnStyle({
  alignment,
}: {
  alignment: "left" | "center" | "centre" | "right";
}): CSSProperties {
  switch (alignment) {
    case "left":
      return {};
    case "center":
    case "centre":
      return { alignItems: "center" };
    case "right":
      return { alignItems: "end" };
  }
}

export function borderRadius({
  top_left,
  top_right,
  bottom_right,
  bottom_left,
}: {
  top_left: number;
  top_right: number;
  bottom_right: number;
  bottom_left: number;
}) {
  return `${top_left}px ${top_right}px ${bottom_right}px ${bottom_left}px`;
}

export function pillRadius(
  radius:
    | "pill"
    | {
        custom_shape: {
          top_left: number;
          top_right: number;
          bottom_right: number;
          bottom_left: number;
        };
      },
) {
  switch (radius) {
    case "pill":
      return "9999px";
    default:
      return borderRadius(radius.custom_shape);
  }
}

export function fieldBorderRadius(
  cornerRadius:
    | "pill"
    | {
        custom_shape: {
          top_left: number;
          top_right: number;
          bottom_right: number;
          bottom_left: number;
        };
      },
  position: "tl" | "tr" | "br" | "bl",
) {
  if (cornerRadius === "pill") {
    return "9999px";
  }

  switch (position) {
    case "tl":
      return `${cornerRadius.custom_shape.top_left}px`;
    case "tr":
      return `${cornerRadius.custom_shape.top_right}px`;
    case "br":
      return `${cornerRadius.custom_shape.bottom_right}px`;
    case "bl":
      return `${cornerRadius.custom_shape.bottom_left}px`;
  }
}

const websafeFonts = [
  "Arial",
  "Courier",
  "Georgia",
  "Palatino",
  "Tahoma",
  "Times New Roman",
  "Verdana",
];

export function font(
  value: string | { custom_font: string } | { uploaded_font: string },
) {
  switch (value) {
    case "global_title_font":
      return "var(--title-font)";
    case "global_body_font":
      return "var(--body-font)";
    case "global_button_font":
      return "var(--button-font)";
    default:
      if (typeof value === "string") {
        throw new Error(`Unexpected typography font: ${value}`);
      }
      if ("uploaded_font" in value) {
        return value.uploaded_font;
      }

      if (websafeFonts.includes(value.custom_font)) {
        return value.custom_font;
      }

      return `var(--font-${value.custom_font
        .split(" ")
        .join("-")
        .toLowerCase()})`;
  }
}

type fontProperties = {
  font_weight: string;
  font_style: string;
  font_path: string;
  font_name: string;
};

export function fontWeight(properties: fontProperties | null) {
  if (!properties) {
    return "normal";
  }

  return properties.font_weight;
}

export function fontStyle(properties: fontProperties | null) {
  if (!properties) {
    return "normal";
  }

  return properties.font_style.toLowerCase();
}

export function color(colour: string | { colour: HSLA }) {
  switch (colour) {
    case "global_title_colour":
      return "var(--title-colour)";
    case "global_body_colour":
    case "global_text_colour":
      return "var(--body-colour)";
    case "global_accent_colour":
      return "var(--accent-colour)";
    case "global_hyperlink_colour":
      return "var(--anchor-colour)";
    case "inherit":
      return "inherit";

    default:
      if (typeof colour === "string") {
        throw new Error(`Unexpected typography colour: ${colour}`);
      }

      return hsla(colour.colour);
  }
}

export function textTransform(transform: string) {
  switch (transform) {
    case "uppercase":
      return "uppercase";
    case "lowercase":
      return "lowercase";
    case "capitalize":
    case "titlecase":
      return "capitalize";
    default:
      return "none";
  }
}

export function boxShadow(shadow: {
  include: boolean;
  h_offset: number;
  v_offset: number;
  blur: number;
  spread: number;
  colour: HSLA | string;
}) {
  if (!shadow.include) {
    return "none";
  }

  const { h_offset, v_offset, blur, spread, colour } = shadow;

  return `${h_offset}px ${v_offset}px ${blur}px ${spread}px ${typeof colour === "object" ? hsla(colour) : colour}`;
}

type FontFamilyChoice = "custom_font" | "uploaded_font";

export type FontFamily = { [key in FontFamilyChoice]?: string };

export function resolveFontFamily(choice: FontFamily, value: fontProperties) {
  return choice["uploaded_font"]
    ? {
        fontFamily: value.font_name,
        fontWeight: value.font_weight,
        fontStyle: value.font_style,
      }
    : {
        fontFamily: choice["custom_font"],
      };
}

function normaliseFontWeight(weight: string | undefined) {
  if (!weight) return "normal";
  switch (weight) {
    case "Bold":
      return "bold";
    case "Regular":
      return "normal";
    default:
      return weight;
  }
}

export function resolveFontFamilyVariables(
  prefix: string,
  choice: FontFamily,
  value: fontProperties,
  weight?: string,
) {
  if (!choice) return {};
  return {
    [`${prefix}-font-name`]: choice["uploaded_font"]
      ? value.font_name
      : websafeFonts.includes(choice["custom_font"] as string)
        ? choice["custom_font"]
        : `var(--font-${choice["custom_font"]?.split(" ").join("-").toLowerCase()})`,
    [`${prefix}-font-weight`]: choice["uploaded_font"]
      ? value.font_weight
      : normaliseFontWeight(weight),
    [`${prefix}-font-style`]: choice["uploaded_font"]
      ? value.font_style.toLocaleLowerCase()
      : "normal",
  };
}

export function findDuplicateKeys(arr: string[]) {
  const duplicateMap = arr.reduce(
    (acc: Record<string, number>, cur: string) => {
      if (acc[cur] === undefined) {
        acc[cur] = 1;
      } else {
        acc[cur]++;
      }
      return acc;
    },
    {} as Record<string, number>,
  );

  // Filter out items with one or less occurences
  return Object.keys(duplicateMap).filter(
    (key: string) => duplicateMap[key] > 1,
  );
}

export type Values = {
  [key: string]: string | number | boolean | string[] | [];
} & {
  email?: string;
  name?: string;
};

type FormDataTuple = readonly [key: string, value: Values[keyof Values]];

export function getValuesFromForm(data: FormData) {
  // check for duplicate keys
  const keys = Array.from(data.keys());
  const duplicateKeys = findDuplicateKeys(keys);

  const values = Object.fromEntries(data.entries()) as Values;

  /**
   * If we have a html form field with the same name i.e. checkbox field
   * then build these values into an array
   */
  if (duplicateKeys.length) {
    return Object.entries(values).reduce(
      (acc: FieldValues, [key, value]: FormDataTuple) => {
        if (duplicateKeys.includes(key)) {
          acc[key] = data.getAll(key);
        } else {
          acc[key] = value;
        }
        return { ...acc };
      },
      {} as Values,
    );
  }

  return values;
}

export type BrandingStyles = CSSProperties & {
  "--block-width": string;
  "--title-font": string;
  "--body-font": string;
  "--button-font": string;
  "--accent-colour": string;
  "--block-background": string;
  "--page-background": string;
  "--title-colour": string;
  "--body-colour": string;
  "--button-text-colour": string;
  "--anchor-colour": string;
};

export function fieldGridClasses(layout: "one_column" | "two_columns") {
  return layout === "one_column"
    ? "grid grid-cols-1 gap-3"
    : "grid grid-cols-2 gap-3";
}

export function applyStageBranding<
  T extends GenericReview | GenericLandingPage,
>(branding: T["branding"]): BrandingStyles {
  return {
    "--block-width": blockWidth(branding.blocks.width),
    "--title-font": branding.fonts.title,
    "--body-font": branding.fonts.body,
    "--button-font": branding.fonts.button,
    "--accent-colour": hsla(branding.colours.accent),
    "--block-background": hsla(branding.colours.block_background),
    "--page-background":
      typeof branding.colours.page_background === "string"
        ? branding.colours.page_background
        : "image" in branding.colours.page_background
          ? `url(${branding.colours.page_background.image})`
          : hsla(branding.colours.page_background.colour),
    "--title-colour": hsla(branding.colours.title),
    "--body-colour": hsla(branding.colours.text),
    "--button-text-colour": hsla(branding.colours.button_text),
    "--anchor-colour": hsla(branding.colours.hyperlinks),
    color: "var(--body-colour)",
    background: "var(--page-background)",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    fontFamily: "var(--body-font)",
  };
}

export function isObject(value: unknown): value is Record<string, unknown> {
  return typeof value === "object" && value !== null;
}
