import { createRoute } from "@tanstack/react-router";
import { LockedForm } from "@/components/forms/locked/form";
import { Route as rootRoute } from "./root";

export const Route = createRoute({
  getParentRoute: () => rootRoute,
  component: RouteComponent,
  path: "/flow/$encodedActivityId/lock",
  loader: ({ params }) => params,
});

function RouteComponent() {
  const { encodedActivityId } = Route.useLoaderData();
  return <LockedForm encodedActivityId={encodedActivityId} />;
}
