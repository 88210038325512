import { type FieldValues, FormProvider, useForm } from "react-hook-form";
import { api } from "@lib/client";
import type { Ticket } from "@lib/ticket";
import { useCallback, useState } from "react";
import { VerificationModal } from "./verification-modal";
import { type Values } from "@lib/utils";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import {
  extractUploadBlock,
  processUgc,
  deleteUgc,
} from "@lib/image-upload.client";
import { DeleteImageProvider } from "@/lib/context";
import type { GenericLandingPage } from "@lib/config/types";

type LandingFormProps = {
  encodedActivityId: string;
  ticket: Ticket | undefined;
  children: React.ReactNode;
  config: GenericLandingPage;
};

export function LandingForm({
  encodedActivityId,
  ticket,
  children,
  config,
}: LandingFormProps) {
  const methods = useForm();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [formState, setFormState] = useState<FieldValues | null>(null);
  const navigate = useNavigate();
  const uploadBlock = extractUploadBlock(config.pages.landing);

  const deleteImage = useCallback(
    (fileName: string) => deleteUgc(encodedActivityId, "claim", fileName),
    [encodedActivityId],
  );

  const { mutateAsync } = useMutation({
    mutationFn: async (formData: FieldValues) => {
      const ugc = formData["ugc"] as File[] | undefined;

      const { ugcMeta, processedImages } = await processUgc({
        methods,
        uploadBlock,
        encodedActivityId,
        flow: "claim",
        ugc,
      });

      if (processedImages) {
        formData["custom-question:ugc"] = processedImages;
      }

      const res = await api.validate.$post({
        json: {
          encodedActivityId,
          ticket,
          formData: {
            ...(formData as Values),
            ugc: ugcMeta,
          },
        },
      });

      return res.json();
    },
    onSuccess: (data) => {
      if (!data.success && "errors" in data) {
        Object.entries(data.errors).forEach(([key, value]) => {
          methods.setError(key, { message: value });
        });
        return;
      }

      if ("redirect" in data) {
        void navigate(data.redirect);
        return;
      }

      if (data.success && "values" in data) {
        setFormState(data.values);
        setDialogOpen(true);
      }
    },
  });

  async function onSubmit(formData: FieldValues) {
    await mutateAsync(formData);
  }

  return (
    <main>
      <DeleteImageProvider value={deleteImage}>
        <FormProvider {...methods}>
          <form onSubmit={(e) => void methods.handleSubmit(onSubmit)(e)}>
            {children}
          </form>
        </FormProvider>
      </DeleteImageProvider>
      <VerificationModal
        encodedActivityId={encodedActivityId}
        isDialogOpen={Boolean(formState) && dialogOpen}
        onDialogOpenChange={(open) => setDialogOpen(open)}
        formValues={formState}
        ticket={ticket}
      />
    </main>
  );
}
