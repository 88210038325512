import type { ConfigPreviewMessage } from "../listeners.client";
import { previewEnv } from "../preview";
import { type GenericLandingPage } from "./types";
import { api } from "@lib/client";

export async function evalFragment({
  preview_config,
  view_path,
  env,
  type,
}: ConfigPreviewMessage) {
  const configType = type === "generic_review_v2.yaml" ? "review" : "landing";

  const res = await api.eval[configType].$post({
    json: {
      fragment: JSON.stringify(preview_config),
      env: { ...previewEnv, ...env },
    },
  });

  if (!res.ok) {
    throw new Error(`Failed to evaluate fragment`);
  }

  const data = await res.json();
  const config = JSON.parse(data.config) as GenericLandingPage;

  return { config, viewPath: view_path, env };
}
