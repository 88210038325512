import { Route as RootRoute } from "@/routes/root";
import {
  PampersRoute,
  Route as LandingRoute,
  CommunityRoute as LandingCommunityRoute,
} from "@/routes/landing";
import { Route as LockRoute } from "@/routes/lock";
import {
  Route as CompleteRoute,
  DoubleOptinRoute as CompleteDoubleOptinRoute,
  CommunityRoute as CompleteCommunityRoute,
} from "@/routes/complete";
import { Route as DelayedRoute } from "@/routes/delayed";
import { Route as DoubleOptinRoute } from "@/routes/double-optin";
import {
  Route as ErrorRoute,
  CommunityRoute as ErrorCommunityRoute,
} from "@/routes/error";
import { Route as ReviewRoute } from "@/routes/review";
import { Route as AlreadyDelayedRoute } from "@/routes/already-delayed";
import { Route as UnsubscribeRoute } from "@/routes/unsubscribe";
import { Route as AlreadyUnsubscribedRoute } from "@/routes/already-unsubscribed";
import { ConfigRoute, ShareableRoute } from "@/routes/preview";

export const routeTree = RootRoute.addChildren([
  PampersRoute,
  // landing pages
  // /flow/$encodedActivityId
  // /flow/community/$encodedActivityId
  LandingRoute,
  LandingCommunityRoute,
  // /flow/$encodedActivityId/lock
  LockRoute,
  // /flow/$encodedActivityId/complete
  // /flow/community/$encodedActivityId/complete
  CompleteRoute,
  CompleteCommunityRoute,
  // /flow/$encodedActivityId/error/$errorType(closed|claimed|out_of_stock)
  // /flow/community/$encodedActivityId/error/$errorType(closed|claimed|out_of_stock)
  ErrorRoute,
  ErrorCommunityRoute,

  // Double Optin
  // /flow/double_optin/$encodedActivityId/$encodedConsentId
  DoubleOptinRoute,
  // /flow/double_optin/$encodedActivityId/$encodedConsentId/complete
  CompleteDoubleOptinRoute,

  //Reviews
  // /flow/review/$encodedActivityId/
  // /flow/review/$encodedActivityId/$step(0-9)
  // /flow/review/$encodedActivityId/reviewed
  ReviewRoute,

  // Previews
  //
  // /flow/$encodedActivityId/config_preview/$ -> redirects to ->
  // /flow/claim|review|double_optin|community/$encodedActivityId/config_preview/$
  // /flow/$encodedActivityId/preview -> redirects to ->
  // /flow/claim|review|double_optin|community/$encodedActivityId/shareable_preview/$
  ConfigRoute,
  ShareableRoute,

  // no-config
  // /flow/unsubscribe/$shortOrderId
  UnsubscribeRoute,
  // /flow/unsubscribe/$shortOrderId/unsubscribed
  AlreadyUnsubscribedRoute,

  // /flow/delayed/$encodedActivityId
  DelayedRoute,
  // /flow/delayed/$encodedActivityId/already-delayed
  AlreadyDelayedRoute,
]);
