"use client";

import { Button } from "@/components/ui/button";
import type { Activity } from "@lib/activity";
import type { GiftingBlock } from "@lib/config/types";
import {
  boxShadow,
  justify,
  resolveFontFamilyVariables,
  type FontFamily,
} from "@lib/utils";
import { useMemo, useCallback, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useTranslations } from "@/components/context/translations";
import { cva } from "class-variance-authority";
import { api } from "@lib/client";

type GiftingBlockProps = GiftingBlock & {
  activity: Activity;
};

function timeout(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

async function createShareUrl(
  activityId: string,
  shareType: {
    gifting: {
      activity_id: unknown;
    };
  },
) {
  const res = await api.share.$post({
    json: {
      activityId,
      shareType: shareType as { gifting: { activity_id: string } },
    },
  });
  return await res.json();
}

export function Gifting({ activity, ...gifting }: GiftingBlockProps) {
  const t = useTranslations();
  const [errorMessage, setErrorMessage] = useState("");

  const {
    data: shareUrl,
    isError,
    isPending: isLoading,
    mutateAsync: createUniqueUrl,
  } = useMutation({
    mutationFn: async () => {
      const [{ shareUrl, success }] = await Promise.all([
        createShareUrl(activity.id, gifting.share_type),
        timeout(800),
      ]);
      if (!success) {
        setErrorMessage(t("Sharing failed: No destination set"));
        throw new Error("No linked activity found");
      }
      if (!shareUrl) {
        setErrorMessage(t("Something went wrong"));
        throw new Error("Failed to generate share URL");
      }
      return shareUrl;
    },
    retry: false,
  });

  const canShare = useCanShare();
  const [showCopiedMessage, setShowCopiedMessage] = useState(false);

  const shareUniqueUrl = useCallback(
    () =>
      void (async () => {
        const url = !shareUrl ? await createUniqueUrl() : shareUrl;
        if (url) {
          if (canShare) {
            try {
              await navigator.share({
                title: document.title,
                text: gifting.share_message,
                url: url || "",
              });
            } catch {}
          } else {
            await navigator.clipboard.writeText(
              `${gifting.share_message} ${url}`,
            );
            setShowCopiedMessage(true);
            setTimeout(() => setShowCopiedMessage(false), 5000);
          }
        }
        return undefined;
      })(),
    [canShare, gifting.share_message, createUniqueUrl, shareUrl],
  );

  const fontFamily = gifting.font as unknown as FontFamily;

  return (
    <div
      data-block-type="Gifting"
      className="relative flex w-full"
      style={{
        justifyContent: justify(gifting),
        ...resolveFontFamilyVariables(
          "--button",
          fontFamily,
          gifting.font_properties,
        ),
      }}
    >
      <Button
        type="button"
        onClick={shareUniqueUrl}
        className="box-content px-8 py-0"
        aria-disabled={false}
        disabled={isLoading || isError}
        style={{
          width: gifting.width === "full" ? "100%" : "auto",
          height: `${gifting.height}px`,
          color: gifting.color,
          backgroundColor: gifting.background,
          borderRadius: `${gifting.border.radius === 9999.0 ? "9999em" : gifting.border.radius}px`,
          border: `${gifting.border.width}px solid ${gifting.border.color}`,
          fontSize: gifting.font_size,
          fontFamily: "var(--button-font-name)",
          fontWeight: "var(--button-font-weight)",
          letterSpacing: gifting.letter_spacing,
          boxShadow: boxShadow(gifting.shadow),
        }}
      >
        {gifting.button_cta}
        {isLoading && <Spinner />}
      </Button>

      {showCopiedMessage && (
        <Toast state="success" message={t("Copied to clipboard")} />
      )}
      {isError && <Toast state="error" message={errorMessage} />}
    </div>
  );
}

function Spinner() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      className="-mr-1 ml-3 h-5 w-5 animate-spin text-white"
      viewBox="0 0 24 24"
    >
      <circle
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
        className="opacity-25"
      ></circle>
      <path
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        className="opacity-75"
      ></path>
    </svg>
  );
}

const ToastStyle = cva(
  "absolute max-w[450px] -bottom-12 border p-2 text-center text-xs z-50",
  {
    variants: {
      state: {
        error: "border-red-700 bg-red-100 text-red-700",
        success: "border-green-700 bg-green-100 text-green-700",
      },
    },
  },
);

function Toast({
  state,
  message,
}: {
  state: "error" | "success";
  message: string;
}) {
  return <div className={ToastStyle({ state })}>{message}</div>;
}

function useCanShare() {
  return useMemo(() => navigator?.share !== undefined, []);
}
