import { createContext, useContext } from "react";

type DeleteImageUploadContextType = (file: string) => Promise<void>;

const DeleteImageContext = createContext<DeleteImageUploadContextType | null>(
  null,
);
export const DeleteImageProvider = DeleteImageContext.Provider;

export function useDeleteImage() {
  const context = useContext(DeleteImageContext);
  if (!context) {
    return () => {};
  }
  return context;
}
