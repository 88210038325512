// This form does not need to be translated as the locked page should only be seen internally

import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { api } from "@lib/client";
import { useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { z } from "zod";

type LockedFormProps = {
  encodedActivityId: string;
};

const formSchema = z.object({
  pin: z
    .string({
      required_error: "The pin you entered is incorrect",
    })
    .min(6, { message: "The pin you entered is incorrect" })
    .max(6),
});

type FormData = z.infer<typeof formSchema>;

export function LockedForm({ encodedActivityId }: LockedFormProps) {
  const form = useForm<FormData>({
    resolver: zodResolver(formSchema),
  });

  const { mutateAsync, isPending } = useMutation({
    mutationFn: async (pin: string) => {
      const res = await api.unlock.$post({
        json: {
          pin,
          encodedActivityId,
        },
      });

      return res.json();
    },
    onSuccess: (data) => {
      if (!data.success) {
        form.setError("pin", { message: "The pin you entered is incorrect" });
        return;
      }

      window.location.href = `/flow/${encodedActivityId}`;
    },
  });

  async function onSubmit({ pin }: FormData) {
    await mutateAsync(pin);
  }

  return (
    <div className="flex flex-col items-center">
      <Card className="mt-40 flex flex-col items-center">
        <h3 className="mb-10 text-xl font-bold">Locked</h3>
        <Form {...form}>
          <form
            className="w-full"
            onSubmit={(e) => void form.handleSubmit(onSubmit)(e)}
          >
            <FormField
              control={form.control}
              name="pin"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      {...field}
                      type="text"
                      maxLength={6}
                      className="text-center text-2xl font-bold tracking-[12px]"
                      style={
                        {
                          "--field-border-size": "1px",
                          "--input-border-color": "hsl(var(--primary))",
                        } as React.CSSProperties
                      }
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button
              type="submit"
              disabled={isPending}
              aria-disabled={isPending}
              className="w-full mt-4"
            >
              Unlock
            </Button>
          </form>
        </Form>
      </Card>
    </div>
  );
}
