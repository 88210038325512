import { z } from "zod";
import {
  extractBlocks,
  type LandingBlock,
  type ImageUpload,
  type ReviewBlock,
} from "./config/types";

export function extractUploadBlock(config: (ReviewBlock | LandingBlock)[]) {
  const blocks = extractBlocks(config);

  const block = blocks.filter(({ type }) => type === "image_upload")[0]?.[
    "values"
  ];

  if (!block) return null;

  return "image_upload" in block ? block : null;
}

export function createImageSchema(config: Omit<ImageUpload, "style"> | null) {
  if (!config) return null;
  const {
    file_size_error_message,
    file_type_error_message,
    file_types,
    max_file_size,
  } = config.image_upload;

  const allowed = file_types.split(", ").map((type) => `image/${type}`);

  return z.object({
    name: z.string(),
    size: z
      .number()
      .refine(
        (size) => size <= max_file_size * 1024 * 1024,
        file_size_error_message.replace(
          "{max_file_size}",
          max_file_size.toString(),
        ),
      ),
    type: z
      .string()
      .refine(
        (type) => allowed.includes(type),
        file_type_error_message.replace("{file_types}", file_types),
      ),
  });
}

export function createImageUploadSchema(
  config: Omit<ImageUpload, "style"> | null,
) {
  if (!config) {
    return z.array(z.never());
  }
  const { max, max_error_message, required_field } = config.image_upload;

  const schema = z
    .array(createImageSchema(config) ?? z.object({}))
    .max(max, max_error_message.replace("{max}", max.toString()))
    .optional()
    .refine(
      (images) => !required_field || (images && images.length > 0),
      "At least one image must be uploaded",
    );

  return schema;
}

export function validateImages(images: File[], schema: z.ZodSchema) {
  const validationResult = schema.safeParse(images);

  if (!validationResult.success) {
    const errors = Object.fromEntries(
      validationResult.error.errors.map((e) => {
        const path = e.path.join(".");
        const message = e.message;

        if (path.startsWith("ugc")) {
          const pathParts = path.split(".");
          const index = parseInt(pathParts[1], 10);
          return [path, { message, index }];
        }

        return [path, message];
      }),
    ) as Record<string, { message: string; index: number } | string>;

    return errors;
  }

  return {};
}
