import type { ImageUpload as ImageUploadType } from "@lib/config/types";
import {
  alignStyle,
  type FontFamily,
  resolveFontFamilyVariables,
  textBlockWidth,
} from "@lib/utils";
import { type CSSProperties } from "react";
import {
  useFormContext,
  type FieldErrors,
  type FieldValues,
} from "react-hook-form";
import { Trash2, ImagePlus } from "lucide-react";
import { useDeleteImage } from "@/lib/context";

type ImageUploadProps = ImageUploadType;

type UGCError = {
  [key: string]: {
    message: {
      index: number;
      message: string;
    };
  };
};

type FlattenedUGCError = {
  index: number;
  message: string;
};

function generateImageErrors(errors: FieldErrors<FieldValues>) {
  const imageErrors: FlattenedUGCError[] | string = Array.isArray(errors.ugc)
    ? ((errors.ugc as unknown as UGCError[])
        .flatMap((ugcError) => {
          const key = Object.keys(ugcError)[0];
          const nestedError = ugcError[key]?.message;

          try {
            const parsedError = (
              typeof nestedError === "string"
                ? JSON.parse(nestedError)
                : nestedError
            ) as { index: number; message: string };

            if (parsedError && "index" in parsedError) {
              return {
                index: parsedError.index,
                message: parsedError.message,
              };
            }
          } catch {
            return null;
          }

          return null;
        })
        .filter(Boolean) as FlattenedUGCError[])
    : [];
  return imageErrors;
}

export function ImageUpload({ image_upload }: ImageUploadProps) {
  const {
    register,
    setValue,
    getValues,
    formState: { errors },
    watch,
  } = useFormContext();

  const deleteImage = useDeleteImage();

  const name = `ugc`;

  const uploadedFiles = (watch(name) as File[]) || [];
  const previews = [...uploadedFiles].map((file) => URL.createObjectURL(file));

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const filesArray = Array.from(e.target.files);
      const newFiles = filesArray.filter(
        (file) =>
          ![...uploadedFiles].some(
            (existingFile) => existingFile.name === file.name,
          ),
      );

      setValue(name, [...(getValues(name) as File[]), ...newFiles], {
        shouldValidate: true,
      });
    }
  };

  const handleRemove = async (index: number) => {
    const file = uploadedFiles[index];

    await deleteImage(file.name);
    const updatedFiles = uploadedFiles.filter((_, i) => i !== index);

    URL.revokeObjectURL(previews[index]);

    setValue(name, updatedFiles, { shouldValidate: false });
  };

  const titleFontFamily = image_upload.title.font as FontFamily;
  const inputFontFamily = image_upload.items.font as FontFamily;
  const promptFontFamily = image_upload.prompt.font as FontFamily;
  const disclaimerFontFamily = image_upload.disclaimer.font as FontFamily;
  const buttonFontFamily = image_upload.add_button.font as FontFamily;

  const allowed = image_upload.file_types
    .split(", ")
    .map((type) => `image/${type}`);

  const invalidImages: number[] = (getValues("invalidUGC") as number[]) ?? [];

  const ugcImageErrors = generateImageErrors(errors);

  return (
    <div
      data-block-type="Image Upload"
      style={{
        ...alignStyle(image_upload),
        maxWidth: textBlockWidth(image_upload.width),
      }}
    >
      <h2
        className="font-base mb-4 font-[family-name:--upload-font-name] font-[--upload-font-weight]"
        style={{
          color: image_upload.title.color,
          ...resolveFontFamilyVariables(
            "--upload",
            titleFontFamily,
            image_upload.title.font_properties,
          ),
        }}
      >
        {image_upload.title.text}
      </h2>
      <p
        style={{
          ...resolveFontFamilyVariables(
            "--prompt",
            promptFontFamily,
            image_upload.prompt.font_properties,
          ),
        }}
        className="font-[family-name:--prompt-font-name] font-[--prompt-font-weight] text-sm mb-4"
        dangerouslySetInnerHTML={{
          __html: image_upload.prompt.text.replace(
            "{max}",
            String(image_upload.max),
          ),
        }}
      />
      <input
        type="file"
        id={`file`}
        className="hidden"
        multiple
        max={image_upload.max}
        accept={allowed.join(", ")}
        aria-describedby="file-list"
        disabled={uploadedFiles.length >= image_upload.max}
        {...register(name)}
        onChange={(e) => handleFileChange(e)}
      />

      {previews.length > 0 && (
        <div
          className="grid gap-2 mb-4"
          style={
            { "--border-color": image_upload.title.color } as CSSProperties
          }
        >
          {previews.map((preview, index) => {
            const error = ugcImageErrors?.find((err) => err.index === index);

            return (
              <div
                key={index}
                style={{
                  background: image_upload.items.background_color,
                  borderRadius:
                    image_upload.items.border.radius === 9999
                      ? "9999em"
                      : `${image_upload.items.border.radius}px`,
                  border: `${image_upload.items.border.width}px solid ${image_upload.items.border.color}`,
                  color: image_upload.items.color,
                  animationDelay: `${index > 0 ? 0.1 * index : 0}s`,
                  ...resolveFontFamilyVariables(
                    "--input",
                    inputFontFamily,
                    image_upload.items.font_properties,
                  ),
                }}
                className={`animate-imageUpload grid grid-cols-uploadedImage items-center gap-3 relative group border rounded-md p-2 scale-75 opacity-0 ${invalidImages.includes(index) || error ? `border-red-500 border shadow-red-500` : ""}`}
              >
                <img
                  className="w-12 aspect-square rounded-sm object-cover cursor-pointer"
                  src={preview}
                  alt={`Preview ${uploadedFiles[index].name}`}
                />
                <span className="text-sm">
                  {uploadedFiles[index].name}
                  {error && (
                    <span className=" text-red-500 text-xs block">
                      {ugcImageErrors[ugcImageErrors.indexOf(error)].message}
                    </span>
                  )}
                </span>
                <span
                  className="place-items-center cursor-pointer grid group-hover:opacity-100 z-10 transition-all delay-100 me-2"
                  aria-label="Remove image"
                  role="button"
                  onClick={() => void handleRemove(index)}
                >
                  <Trash2 size="18" />
                </span>
              </div>
            );
          })}
        </div>
      )}

      <label
        htmlFor={`file`}
        style={{
          ...resolveFontFamilyVariables(
            "--button",
            buttonFontFamily,
            image_upload.add_button.font_properties,
            image_upload.add_button.font_weight,
          ),
          color: image_upload.add_button.color,
          background: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23${image_upload.add_button.border.color.replace("#", "")}' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='3' stroke-linecap='square'/%3e%3c/svg%3e")`,
        }}
        className={`font-[family-name:--button-font-name] font-[--button-font-weight] group grid gap-3 place-items-center p-6 cursor-pointer ${uploadedFiles.length === image_upload.max ? "hidden" : ""}`}
      >
        <ImagePlus size={24} className="group-hover:scale-125 transition-all" />
        {image_upload.add_button.text}
      </label>
      <div
        className="font-[family-name:--disclaimer-font-name] font-[--disclaimer-font-weight] text-xs pt-4"
        style={{
          ...resolveFontFamilyVariables(
            "--disclaimer",
            disclaimerFontFamily,
            image_upload.disclaimer.font_properties,
          ),
        }}
        dangerouslySetInnerHTML={{ __html: image_upload.disclaimer.text }}
      />

      <div className="hidden" id="file-list" aria-live="polite">
        {[...uploadedFiles].map((f) => f.name)}
      </div>
      {"ugc" in errors && !ugcImageErrors.length ? (
        <p className="text-red-500 pt-3 text-sm">
          {typeof errors.ugc?.message === "string" ? errors.ugc.message : ""}
        </p>
      ) : null}
    </div>
  );
}
