import type { ApiType } from "@server/routes/api";
import { hc } from "hono/client";

declare const sessionId: string;

export const api = hc<ApiType>(location.origin + "/api", {
  headers: {
    "X-Session-Id": sessionId,
    "X-Requested-With": "XMLHttpRequest",
  },
});
