"use client";

import { type Config } from "@lib/config/utils";
import { extractUploadedFonts, getFontFace } from "@lib/config/fonts";

type FontsProps = {
  config: Config;
};

export function PreviewFonts({ config }: FontsProps) {
  const fonts = extractUploadedFonts(config);
  const fontFaces = fonts.map(getFontFace).join(" ");

  return <style>{fontFaces}</style>;
}
