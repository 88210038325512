import { createRoute, notFound } from "@tanstack/react-router";
import { Error, type ErrorProps } from "@/components/pages/error";
import { Route as rootRoute, getContext } from "./root";
import type { Activity } from "@lib/activity";
import { z } from "zod";

const ErrorTypesSchema = z.enum(["closed", "claimed", "out_of_stock"]);
type Config = ErrorProps["config"];

export const Route = createRoute({
  getParentRoute: () => rootRoute,
  path: "/flow/$encodedActivityId/error/$errorType",
  loader: ({ params }) => {
    const errorTypeValidation = ErrorTypesSchema.safeParse(params.errorType);
    if (errorTypeValidation.success === false) {
      throw notFound();
    }
    const errorType = errorTypeValidation.data;

    const data = getContext<{ activity: Activity; config: Config }>();
    return {
      errorType,
      ...data,
    };
  },
  component: function Page() {
    const { activity, config, errorType } = Route.useLoaderData();
    return (
      <Error
        activity={activity}
        config={config}
        page={errorType}
        type="claim"
      />
    );
  },
});

export const CommunityRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/flow/community/$encodedActivityId/error/$errorType",
  loader: ({ params }) => {
    const errorTypeValidation = ErrorTypesSchema.safeParse(params.errorType);
    if (errorTypeValidation.success === false) {
      throw notFound();
    }
    const errorType = errorTypeValidation.data;

    const data = getContext<{ activity: Activity; config: Config }>();
    return {
      errorType,
      ...data,
    };
  },
  component: function Page() {
    const { activity, config, errorType } = CommunityRoute.useLoaderData();
    return (
      <Error
        activity={activity}
        config={config}
        page={errorType}
        type="claim"
      />
    );
  },
});
