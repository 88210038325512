import { FormProvider, useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { api } from "@lib/client";
import { useNavigate } from "@tanstack/react-router";
type DoubleOptinFormProps = {
  encodedActivityId: string;
  consentId: string;
  children: React.ReactNode;
};

export function DoubleOptinForm({
  encodedActivityId,
  consentId,
  children,
}: DoubleOptinFormProps) {
  const methods = useForm();
  const navigate = useNavigate();
  const { mutateAsync } = useMutation({
    mutationFn: async () => {
      const res = await api.consent.$post({
        json: {
          encodedActivityId,
          consentId,
        },
      });

      return res.json();
    },
  });
  const onSubmit = async (_: unknown) => {
    const res = await mutateAsync();
    if (res.success && res.redirect) {
      await navigate(res.redirect);
    }
  };

  return (
    <main>
      <FormProvider {...methods}>
        <form
          className="grid gap-8"
          onSubmit={(e) => void methods.handleSubmit(onSubmit)(e)}
        >
          {children}
        </form>
      </FormProvider>
    </main>
  );
}
