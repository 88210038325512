import { init, tanstackRouterBrowserTracingIntegration } from "@sentry/react";
import type { AnyRouter } from "@tanstack/react-router";
declare const env: string;

export function initSentry(router: AnyRouter) {
  if (import.meta.env.PROD) {
    init({
      dsn: "https://8e508cc89efad8242a2a9cc55fa9e1b6@o6859.ingest.us.sentry.io/4506195728924672",
      environment: env,

      integrations: [
        tanstackRouterBrowserTracingIntegration(router),
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      ],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for tracing.
      tracesSampleRate: 0.01,

      // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
      tracePropagationTargets: [/^\//],

      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 0.1,
    });
  }
}
