import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import type { FormInputField } from "@lib/config/form";
import { useFormContext } from "react-hook-form";

type FormInputProps = FormInputField;

export function FormInput({ defaultValue, ...props }: FormInputProps) {
  const { control } = useFormContext();
  return (
    <FormField
      control={control}
      name={props.name}
      defaultValue={defaultValue ?? ""}
      render={({ field }) => (
        <FormItem>
          <FormLabel
            style={{
              fontSize: "var(--label-font-size)",
              fontFamily: "var(--label-font-name, var(--font-family))",
              fontStyle: "var(--label-font-style, var(--font-style))",
              fontWeight: "var(--label-font-weight, var(--font-weight))",
              color: "var(--label-color)",
            }}
            dangerouslySetInnerHTML={{
              __html: props.label ?? "",
            }}
          />
          {props.prompt ? (
            <div
              id={`prompt-${field.name}`}
              className="block [&:not(:empty)]:mb-[1.5rem]"
              dangerouslySetInnerHTML={{
                __html: props.prompt ?? "",
              }}
              style={{
                fontSize: "var(--prompt-font-size)",
                fontFamily: "var(--prompt-font-name, var(--font-family))",
                fontStyle: "var(--prompt-font-style, var(--font-style))",
                fontWeight: "var(--prompt-font-weight, var(--font-weight))",
                color: "var(--prompt-font-color)",
              }}
            ></div>
          ) : null}
          <FormControl>
            <Input
              {...field}
              {...props}
              className="px-[1em] py-[0.5em] shadow-none"
              style={{
                minBlockSize: "var(--input-block-size)",
                fontFamily: "var(--input-font-name, var(--font-family))",
                fontStyle: "var(--input-font-style, var(--font-style))",
                fontWeight: "var(--input-font-weight, var(--font-weight))",
                color: "var(--input-color)",
              }}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    ></FormField>
  );
}
