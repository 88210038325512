import { useTranslations } from "@/components/context/translations";

export function NotFound() {
  const t = useTranslations();

  return (
    <div className="text-center shadow-lg shadow-gray-300 border border-gray-100 rounded-lg w-[363px] mx-auto my-20">
      <div className="flex flex-col items-center justify-between p-6">
        <h1 className="text-2xl font-semibold pb-4 leading-[33.6px]">
          {t("Page Not Found.")}
        </h1>
        <p className="pb-4 text-base font-normal">
          {t("Sorry, we couldn't find that page")}
        </p>
      </div>
    </div>
  );
}
