import { LandingForm } from "@/components/forms/landing/form";
import { Landing } from "@/components/pages/landing";
import { type Activity } from "@lib/activity";
import { type GenericLandingPage } from "@lib/config/types";
import type { Ticket } from "@lib/ticket";
import { createRoute, notFound } from "@tanstack/react-router";
import { Route as rootRoute, getContext } from "./root";

export type LandingProps = {
  activity: Activity;
  config: GenericLandingPage;
  ticket: Ticket | undefined;
  encodedActivityId: string;
};

// Pampers Only
export const PampersRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/",
  loader: () => {
    const ctx = getContext<LandingProps | undefined>();
    if (!ctx) throw notFound();
    return ctx;
  },
  component: function Page() {
    const props = PampersRoute.useLoaderData();
    return <LandingPage {...props} />;
  },
});

export const Route = createRoute({
  getParentRoute: () => rootRoute,
  path: "/flow/$encodedActivityId",
  loader: getContext<LandingProps>,
  component: function Page() {
    const props = Route.useLoaderData();
    return <LandingPage {...props} />;
  },
});

export const CommunityRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/flow/community/$encodedActivityId",
  loader: getContext<LandingProps>,
  component: function Page() {
    const props = CommunityRoute.useLoaderData();
    return <LandingPage {...props} />;
  },
});

export function LandingPage({
  activity,
  config,
  ticket,
  encodedActivityId,
}: LandingProps) {
  return (
    <LandingForm
      encodedActivityId={encodedActivityId}
      ticket={ticket}
      config={config}
    >
      <Landing
        activity={activity}
        config={config}
        ticket={ticket}
        flowConsents={activity.claimConsents}
        page="landing"
      />
    </LandingForm>
  );
}
