import type { useForm } from "react-hook-form";
import { api } from "./client";
import { type ImageUpload } from "./config/types";
import { createImageUploadSchema } from "./image-upload";
export * from "./image-upload";

async function processImage(
  encodedActivityId: string,
  flow: string,
  id: string,
  file: File,
): Promise<{ uuid: string }> {
  const form = new FormData();
  form.append("file", file);
  form.append("encodedActivityId", encodedActivityId);
  form.append("flow", flow);
  form.append("id", id);

  const response = (await fetch(api["upload-image"].$url(), {
    method: "POST",
    body: form,
  })) as Awaited<ReturnType<(typeof api)["upload-image"]["$post"]>>;

  // TODO: handle validation ERRORS (there are non yet)

  if (!response.ok) {
    throw new Error("Failed to upload image");
  }

  const result = await response.json();
  if (!result.success || !result.value) {
    throw new Error("Failed to upload image");
  }
  return { uuid: result.value };
}

type UgcMeta = { name: string; size: number; type: string };

let alreadyUploadedFiles: (UgcMeta & { uuid: string })[] = [];

export async function processUgc({
  methods,
  uploadBlock,
  encodedActivityId,
  flow,
  id = "",
  ugc: ogUgc = [],
}: {
  methods: ReturnType<typeof useForm>;
  uploadBlock: Omit<ImageUpload, "style"> | null;
  encodedActivityId: string;
  flow: string;
  id?: string;
  ugc?: File[];
}): Promise<{
  ugcMeta: UgcMeta[];
  processedImages?: string[];
}> {
  const { setError, clearErrors, setValue, unregister } = methods;
  if (!uploadBlock) {
    return {
      ugcMeta: [],
    };
  }

  const ugc = [...ogUgc];

  const ugcMeta = ugc.map((file) => ({
    name: file.name,
    size: file.size,
    type: file.type,
  }));

  //validate the image uplaod input first before any processing
  const schema = createImageUploadSchema(uploadBlock);
  const validationResult = schema.safeParse(ugcMeta);

  if (validationResult.success) {
    try {
      const processedImages = await Promise.all(
        ugc
          .filter((f) => !alreadyUploadedFiles.find((a) => a.name == f.name))
          .map(async (file) => {
            const response = await processImage(
              encodedActivityId,
              flow,
              id,
              file,
            );
            return {
              ...ugcMeta.find((m) => m.name === file.name)!,
              uuid: response.uuid,
            };
          }),
      );

      alreadyUploadedFiles = [
        ...alreadyUploadedFiles,
        ...processedImages.filter((i) => i.uuid !== "invalid"),
      ];

      console.log(alreadyUploadedFiles);

      const hasInvalidImage = processedImages.some((i) => i.uuid === "invalid");

      const invalidIndices = processedImages
        .map((item, index) => (item.uuid === "invalid" ? index : -1))
        .filter((index) => index !== -1);

      if (!hasInvalidImage) {
        clearErrors("ugc");
        unregister("invalidUGC");

        return {
          ugcMeta,
          processedImages: alreadyUploadedFiles.map((i) => i.uuid),
        };
      } else {
        setValue("invalidUGC", invalidIndices);
      }
    } catch (error) {
      console.error("Error processing images:", error);
    }
  }
  setError("ugc", {
    type: "manual",
    message: `${uploadBlock?.image_upload.invalid_error_message}`,
  });
  throw new Error("Image processing failed. Please try again.");
}

export async function deleteUgc(
  encodedActivityId: string,
  flow: string,
  fileName: string,
): Promise<void> {
  const file = alreadyUploadedFiles.find((a) => a.name === fileName);
  if (file) {
    const response = await api["upload-image"][":encodedActivityId"][":flow"][
      ":uuid"
    ].$delete({
      param: {
        encodedActivityId,
        flow,
        uuid: file.uuid,
      },
    });
    if (response.ok) {
      alreadyUploadedFiles = alreadyUploadedFiles.filter(
        (a) => a.uuid !== file.uuid,
      );
    }
  }
}
