import { Card } from "@/components/ui/card";
// FIXME: this page should probably be translated

export function AlreadyDelayed() {
  return (
    <div className="flex flex-col items-center">
      <Card className="mt-40 text-center">
        <h1 className="text-lg font-semibold mb-4">Thank you!</h1>
        <p className="leading-relaxed">
          You have already told us why you you haven't tried your sample yet.
        </p>
      </Card>
    </div>
  );
}
