// This file is just here to create unions of types from config and export cleaner types
// Nothing else should have to import from `lib/types.ts` directly
import type * as specs from "@sopost/config-spec";

export type ActivityFlows = specs.ActivityFlows.Value;
export type GenericLandingPage = specs.GenericLandingPageV3.Value;
export type VerificationEmail = specs.VerificationEmail.Value;
export type DoubleOptinPage = specs.DoubleOptinLanding.Value;
export type GenericReview = specs.GenericReviewV2.Value;
export type GenericReviewPages = GenericReview["pages"];

export type GenericReviewPage = specs.ReviewFlowPages.Value;

type PickChoice<T, V extends UnionKey<T>> = Extract<
  T,
  { [k in V]: unknown }
>[V];

export function extractBlocks<
  T extends LandingBlock | LandingField | CustomQuestion | ReviewBlock,
>(blocks: T[]): TypeExtract<T>[] {
  return blocks.map((block) => {
    const [type] = Object.keys(block);
    const values = (block as unknown as Record<string, unknown>)[type];
    return { type, values } as TypeExtract<T>;
  });
}

export type UnionKey<T> = T extends T ? keyof T : never;
export type TypeExtract<T> = T extends T
  ? { type: keyof T; values: T[keyof T] }
  : never;
export type ConfigType =
  | specs.ActivityFlows.Value
  | specs.VerificationEmail.Value
  | specs.GenericLandingPageV3.Value
  | specs.DoubleOptinLanding.Value
  | specs.GenericReviewV2.Value;

export type LandingBlock =
  | specs.GenericLandingBlockV2.Value
  | specs.GenericLandingBlock.Value;

export type LandingField = specs.LandingPageFields.Value;
export type Settings = specs.GenericLandingPageV3.Value["settings"];

export type LandingPageBlock = TypeExtract<LandingBlock>;
export type LandingPageField = TypeExtract<LandingField>;
export type TextInputField = PickChoice<
  PickChoice<LandingField, "email">["input_type"],
  "text_input"
>;

export type ReviewBlock = specs.GenericReviewBlock.Value;

export type Block = ReviewBlock | LandingBlock;

export type ReviewField = specs.ReviewFields.Value;
export type ReviewPageField = TypeExtract<ReviewField>;

export type ReviewPageBlock = TypeExtract<ReviewBlock>;

export type SelectField = PickChoice<
  PickChoice<LandingField, "email">["input_type"],
  "select"
>;

export type ReviewFieldNickname = Extract<
  ReviewField,
  { nickname: Record<string, unknown> }
>;
export type ReviewFieldReview = Extract<
  ReviewField,
  { review: Record<string, unknown> }
>;
export type ReviewFieldRating = Extract<
  ReviewField,
  { star_rating: Record<string, unknown> }
>;
export type ReviewFieldReviewTitle = Extract<
  ReviewField,
  { review_title: Record<string, unknown> }
>;

export type NameInputField = PickChoice<LandingField, "name">;

export type FormFieldOption = UnionKey<NameInputField["input_type"]>;

export type StageBranding = specs.GenericLandingPageV3.Value["branding"];

export type CallToActionBlock = PickChoice<Block, "call_to_action">;
export type CallToActionBlockV2 = PickChoice<Block, "call_to_action_v2">;
export type ConsentsBlock = PickChoice<Block, "consents">;
export type ConsentsBlockV2 = PickChoice<Block, "consents_v2">;

export type LandingPageCustomQuestions = PickChoice<Block, "custom_questions">;
export type CustomQuestionsConfigType =
  LandingPageCustomQuestions["custom_questions"];
export type CustomQuestionsConfig =
  CustomQuestionsConfigType["questions"][number];
export type CustomQuestion = CustomQuestionsConfig;
export type CustomQuestionsBlock = LandingPageCustomQuestions;
export type CustomQuestionsV2 = PickChoice<Block, "custom_questions_v2">;

export type Question = TypeExtract<CustomQuestion>;
export type ShortAnswer = PickChoice<CustomQuestion, "short_answer">;
export type MultipleChoice = PickChoice<CustomQuestion, "multiple_choice">;
export type DateInput = PickChoice<CustomQuestion, "date_input">;
export type StarRating = PickChoice<CustomQuestion, "star_rating">;

export type MultipleChoiceOtherOption = MultipleChoice["other_option"];

export type MultipleChoiceLayoutOption = MultipleChoice["desktop_layout"];

export type MultipleChoiceMultipleSelectionOption =
  MultipleChoice["enable_multiple_answer_selection"];

export type MultipleChoiceSelectionAnswerAmount = Exclude<
  MultipleChoiceMultipleSelectionOption,
  string
>["enable_multiple_choice_selection"]["answer_amount"];

export type MultipleChoiceAnswer = MultipleChoice["answers"][number];

export type FormBlock = PickChoice<LandingBlock, "form">;
export type FormBlockV2 = PickChoice<LandingBlock, "form_v2">;

export type GiftingBlock = PickChoice<LandingBlock, "gifting">;
export type ImageBlock = PickChoice<Block, "image">;
export type ImageBlockV2 = PickChoice<Block, "image_v2">;

export type LogoBlock =
  | PickChoice<Block, "logo_bar">
  | PickChoice<specs.GenericLandingBlock.Value, "logo_bar">;

export type LogoBlockV2 = PickChoice<Block, "logo_bar_v2">;
export type PointerBlock = PickChoice<Block, "pointer">;
export type PointerBlockV2 = PickChoice<Block, "pointer_v2">;
export type ProductSelectionBlock = PickChoice<
  LandingBlock,
  "product_selection"
>;
export type ProductSelectionBlockV2 = PickChoice<
  LandingBlock,
  "product_selection_v2"
>;
export type SeparatorBlock = PickChoice<Block, "separator">;
export type TextBlock = PickChoice<Block, "text">;
export type TextBlockV2 = PickChoice<Block, "text_v2">;
export type TitleBlock = PickChoice<Block, "title">;
export type TitleBlockV2 = PickChoice<Block, "title_v2">;
export type SubmitButtonBlock = PickChoice<Block, "submit_button">;
export type SubmitButtonBlockV2 = PickChoice<Block, "submit_button_v2">;
export type CustomHTMLBlock = PickChoice<Block, "custom_html">;
export type ImageUpload = PickChoice<Block, "image_upload">;

export type SyndicatorQuestionsBlock = PickChoice<
  ReviewBlock,
  "syndicator_questions"
>;
export type SyndicatorQuestionsV2Block = PickChoice<
  ReviewBlock,
  "syndicator_questions_v2"
>;
export type SyndicatorQuestions =
  SyndicatorQuestionsV2Block["syndicator_questions"]["fields"];

export type SyndicatorQuestionField = SyndicatorQuestions extends {
  [key: string]: unknown;
}
  ? keyof SyndicatorQuestions
  : never;

export type SyndicatorQuestionFieldKeys<T> = T extends {
  [key: string]: unknown;
}
  ? keyof T
  : never;

export type SQValueKeys = SyndicatorQuestionFieldKeys<specs.ReviewFields.Value>;
export type ProgressButtonBlock = PickChoice<ReviewBlock, "progress_button">;
export type ProgressButtonBlockV2 = PickChoice<
  ReviewBlock,
  "progress_button_v2"
>;
export type ShopNowButtonBlock = PickChoice<ReviewBlock, "shop_now_button">;
export type ShopNowButtonBlockV2 = PickChoice<
  ReviewBlock,
  "shop_now_button_v2"
>;

/** Shareable preview types for page listing */
export type ReviewPageList =
  | {
      type: "standard_page";
      blocks: ReviewBlock[];
    }
  | {
      type: "per_product_page";
      blocks: ReviewBlock[];
    }
  | {
      type: "thank_you";
      blocks: ReviewBlock[];
    };

export type ReviewPageType = ReviewPageList["type"];

export type ReviewStep = {
  type: ReviewPageType;
  step: number;
};

export type PerProductPage = Extract<
  GenericReviewPages,
  { per_product_page: Record<string, unknown> }
>;
export type StandardPage = Extract<
  GenericReviewPages,
  { standard_page: Record<string, unknown> }
>;
export type ThankYouPage = Extract<
  GenericReviewPages,
  { thank_you: Record<string, unknown> }
>;
