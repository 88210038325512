import { Blocks } from "@/components/blocks/blocks";
import { Card } from "@/components/ui/card";
import type { Activity } from "@lib/activity";
import type {
  DoubleOptinPage,
  GenericLandingPage,
  GenericReview,
} from "@lib/config/types";
import { resolvePage, type LandingPageChoice } from "@lib/landing";
import { resolveReviewPage } from "@lib/review";

export type ErrorProps = {
  activity: Activity;
  config: GenericLandingPage | DoubleOptinPage | GenericReview;
  page: LandingPageChoice | number;
  type: string;
};

export function Error(props: ErrorProps) {
  const blocks =
    props.type === "review"
      ? resolveReviewPage(props.config as GenericReview, props.page as string)
      : resolvePage(
          props.config as GenericLandingPage,
          props.page as LandingPageChoice,
        );

  return (
    <div className="flex flex-col items-center">
      <Card className="mt-40">
        <Blocks blocks={blocks} {...props} />
      </Card>
    </div>
  );
}
