import { useState } from "react";
import { Button } from "@/components/ui/button";
import { useMutation } from "@tanstack/react-query";
import { api } from "@lib/client";
import { logger } from "@lib/logger";
import { useTranslations } from "@/components/context/translations";
import { createRoute, useNavigate } from "@tanstack/react-router";
import { Route as rootRoute } from "./root";

export const Route = createRoute({
  getParentRoute: () => rootRoute,
  path: "/flow/unsubscribe/$shortOrderId",
  loader: ({ params }) => params,
  component: function Page() {
    const { shortOrderId } = Route.useLoaderData();
    return <UnsubscribePage shortOrderId={shortOrderId} />;
  },
});

export function UnsubscribePage({ shortOrderId }: { shortOrderId: string }) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const t = useTranslations();

  const { mutateAsync } = useMutation({
    mutationFn: async (shortOrderId: string) => {
      try {
        const unsubscribeResponse = await api.unsubscribe.$post({
          json: {
            formData: shortOrderId,
          },
        });

        const unsubscribeResult = await unsubscribeResponse.json();

        if (!unsubscribeResult.success) {
          throw new Error(unsubscribeResult.error || "Error unsubscribing");
        }

        return unsubscribeResult;
      } catch (error) {
        throw error; // Let the mutation handle any errors
      }
    },
    onError: (error) => {
      logger.error(error, "Error handling the mutation");
      setLoading(false); // Reset loading on error
    },
    onSuccess: async (data) => {
      if (data.success) {
        await navigate({
          to: "/flow/unsubscribe/$shortOrderId/unsubscribed",
          params: { shortOrderId },
        });
      }
      setLoading(false); // Reset loading after success
    },
  });

  async function onSubmit() {
    try {
      setLoading(true); // Set loading when mutation starts
      await mutateAsync(shortOrderId);
    } catch (error) {
      logger.error(error, "Error handling unsubscribe");
      setLoading(false); // Reset loading on error
    }
  }

  return (
    <div className="text-center shadow-lg shadow-gray-300 border border-gray-100 rounded-lg w-[363px] mx-auto my-20">
      <div className="flex flex-col items-center justify-between p-6">
        <h1 className="text-2xl font-semibold pb-4 leading-[33.6px]">
          {t("Unsubscribe")}
        </h1>
        <p className="pb-4 text-base font-normal">
          {t(
            "Click the button below to unsubscribe from emails relating to this campaign.",
          )}
        </p>
        <Button
          className="bg-gray-100 text-black shadow-lg shadow-gray-300 border border-gray-300 w-[314px] h-[42px] text-base font-normal transition-all duration-300 hover:border-2 hover:bg-gray-100"
          onClick={() => {
            void onSubmit();
          }}
          disabled={loading}
        >
          {loading ? t("Unsubscribing...") : t("Unsubscribe")}
        </Button>
      </div>
    </div>
  );
}
